
#header-bar {
    background: #333;
    color: #FFFFFF;
    font-size: 1.7rem;
    padding-left: 25px;
    font-family: 'Playfair Display', serif;
}

.list {
    display: flex;
    gap: 1rem;
}

.list a {
    text-decoration: none;
    color: var(--color-primary-400);
}

.list a:hover,
.list a.active {
    color: var(--color-primary-800);
    text-decoration: underline;
}