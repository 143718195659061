.event-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    padding: 0.5rem;
    margin: 1rem 0;
    background-color: #006600;
}

.event-item__description {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
    flex-flow: column-reverse;
    justify-content: flex-start;
    vertical-align: top;
    flex: 1;
}

.event-item h2 {
    font-size: 1rem;
    flex: 1;
    margin: 0 1rem;
    color: white;
    display: block;
}

.event-item__price {
    font-size: 1rem;
    font-weight: bold;
    color: white;
    background-color: #40005d;
    border: 1px solid white;
    padding: 0.5rem;
    border-radius: 12px;
}


@media (min-width: 580px) {
    .event-item__description {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }

    .event-item__description h2 {
        font-size: 1.25rem;
    }

    .event-item__price {
        font-size: 1.25rem;
        padding: 0.5rem 1.5rem;
    }
}