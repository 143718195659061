.outlet-body {
    background-image: url("body-bg.jpg");
}

DIV.pageBody {
   width: 100% !important;
}

@media screen and (max-width: 480px) {
    .hidden-on-mobile {
        display: none;
    }
}