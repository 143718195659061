.event-date-container {
    padding: 10px;
    color: #666;
}

.event-date-month {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}

.event-date-day {
    font-size: 3rem;
    text-align: center;
}