.footer-background {
    background-color: #666666;
    margin-top: 2em;
    padding: 20px;
    color: #fff;
}

.footer-background a {
    color: #ffffff;
    text-decoration: none;
}